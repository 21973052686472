/* General Styles */

.darkMain {
  color: #39871a;
}

.mediumMain {
  color: #6bb72c;
}

.lightMain {
  color: #a5e840;
}

.button {
  background-color: #39871a !important;
  color: #fff !important;
}

.button:hover{
  background-color: #6bb72c !important;
}

.cursor-pointer {
  cursor: pointer;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
}

.App {
  min-height: 100vh;
  background: linear-gradient(135deg, #e6f0e9, #d1e7f0);
  padding: 20px;
}

h1 {
  color: #6bb72c;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .login-container, .devices-container {
    margin: 20px;
    padding: 20px;
  }
  .device-list {
    grid-template-columns: 1fr;
  }
}