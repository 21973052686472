.blockly-workspace {
    height: 400px;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .code-textarea {
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
    resize: vertical;
  }