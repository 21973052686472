.peek-toggle {
    cursor: pointer;
  }
  
  .match {
    border-color: #28a745 !important;
  }
  
  .mismatch {
    border-color: #dc3545 !important;
  }